import React, {useContext, useEffect} from "react";
import { Context } from "../store/appContext.js";
import { Spinner } from "react-bootstrap";


export const CallToGratitudeEs = () => {
  const { store } = useContext(Context);
  
  useEffect(() => {
    if (store.paypal) {
      store.paypal.HostedButtons({
        hostedButtonId: "6JV9LJYYGJPUS"
      })
      .render("#paypal-container-6JV9LJYYGJPUS")
    }
    }, [store.paypal])


  return (
    <div className="d-flex justify-content-center mt-3" >
      {store.paypal ? 
        <div id="paypal-container-6JV9LJYYGJPUS"></div>
      : 
        <Spinner/>
      }
    </div>
  );
}
