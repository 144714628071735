import React, {useContext, useState} from "react";
import {Col, Container, Row, Form} from "react-bootstrap";
import publications from '../assets/data/publications.json';
import Card from "react-bootstrap/Card";
import {Context} from "../store/appContext";
import {Typeahead} from 'react-bootstrap-typeahead';


export const Publications = () => {
    const {store, actions} = useContext(Context);
    const [selection, setSelection] = useState([]);  // Typeahead setting
    let i = store.isSpanish ? 0 : 1;
    let text = [
        {
            'flag': '🇪🇸 ESP',
            'title': 'Publicaciones',
            'labelKey': 'title_es',
            'placeholder': 'Encuentra una publicación'
        },
        {
            'flag': '🇺🇲 ENG',
            'title': 'Publications',
            'labelKey': 'title_en',
            'placeholder': 'Find a publication'
        }
    ];

    return (
        <Container className={"my-5"}>
            <Container className={"py-5"}>
                <Card.Title className={"display-5 fw-bold pb-1 my-1 my-md-2 my-lg-3 text-lg-center"}>
                    {text[i].title}
                </Card.Title>
                <Container fluid={'sm'} className={'pb-1 my-1 my-md-2 my-lg-3'}>
                    <Row xs={1} className={"g-1 justify-content-md-center"}>
                        <Col lg={8}>
                            <Form.Group>
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey={text[i].labelKey}
                                    onChange={setSelection}
                                    options={publications}
                                    placeholder={text[i].placeholder}
                                    selected={selection}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={'sm'}>
                    {/* <Row xs={1} sm={1} md={2} lg={3} className={"g-1 g-sm-1 g-md-2 g-lg-3"}> */}
                    <Row xs={1} className={"g-1 justify-content-md-center"}>
                        {publications
                            .filter(element => selection.length === 0 ? true : element.id === selection[0].id)
                            .map((element) => {
                                    return (
                                        <Col lg={8} key={element.id}>
                                            <Card border="warning" className={'p-3'}>
                                                <Card.Header className={"bg-dark"}>
                                                    <Card.Subtitle className={"text-warning"}>
                                                        {element.category_name}
                                                    </Card.Subtitle>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Card.Title>
                                                        {store.isSpanish ? element.title_es : element.title_en}
                                                    </Card.Title>
                                                    <Card.Text>
                                                        {store.isSpanish ? element.resume_es : element.resume_en}
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Card.Subtitle className={"d-flex justify-content-between"}>
                                                        <Card.Link className={"btn btn-warning"} href={element.url}
                                                                   target={"_blank"}>
                                                            <span>{store.isSpanish ? 'Publicación completa en ' : 'Complete publication on '} {element.source_name}</span>
                                                        </Card.Link>
                                                        <span className="d-none d-sm-block">{element.language}</span>
                                                    </Card.Subtitle>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    )
                                }
                            )
                        }
                    </Row>
                </Container>
            </Container>
        </Container>
    )
}