import React, {useContext} from "react";
import {Context} from "../store/appContext.js";
// React-Bootstrap
import {Button, Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
// Components & Logo
import {NavbarUtil} from "./NavbarUtil";
import {CallToActionPrimary} from "./CallToActionPrimary";
import logo from "../assets/images/hcht-logo-navbar.png";

export const NavbarMain = () => {
  const {store, actions} = useContext(Context);
  let linkClass = 'link-warning text-decoration-none';
  let subLinkClass = 'text-secondary text-decoration-none';
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag' : '🇪🇸 ESP',
      'logoAlt': 'Héctor Chocobar Torrejón',
      'siteName': 'Héctor Chocobar Torrejón',
      'siteDescription': 'Ingeniero en Sistemas de Información',
      'menu1': 'Acerca de mí',
      'pathMenu1': '/',
      'menu2': 'Publicaciones',
      'pathMenu2': '/publications',
      'menu3': 'Tu agradecimiento',
      'pathMenu3': '/gratitude',
    },
    {
      'flag' : '🇺🇲 ENG',
      'logoAlt': 'Hector Chocobar-Torrejon',
      'siteName': 'Hector Chocobar-Torrejon',
      'siteDescription': 'BE, CS – Bachelor of Engineering in Computer Science',
      'menu1': 'About Me',
      'pathMenu1': '/',
      'menu2': 'Publications',
      'pathMenu2': '/publications',
      'menu3': 'Your gratitude',
      'pathMenu3': '/gratitude',
    }
  ];

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand href={"/"}>
          <img alt={text[i].logoAlt} src={logo} width="40" height="40" className="d-inline-block align-top"/>
        </Navbar.Brand>
        <Navbar.Text className={'d-none d-sm-inline'}>
          <strong>{text[i].siteName}</strong><br/>
          <small>{text[i].siteDescription}</small>
        </Navbar.Text>
        <Navbar.Text className={'d-inline d-sm-none'}>
          <CallToActionPrimary/>
        </Navbar.Text>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" className={'justify-content-end'}>
          <Nav>
            <Nav.Link className={linkClass} href={text[i].pathMenu1}>{text[i].menu1}</Nav.Link>
            <Nav.Link className={linkClass} href={text[i].pathMenu2}>{text[i].menu2}</Nav.Link>
            <Nav.Link className={linkClass} href={text[i].pathMenu3}>{text[i].menu3}</Nav.Link>
            <NavbarUtil/>
            <Nav.Item className={'d-none d-sm-inline'}>
              <CallToActionPrimary />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
