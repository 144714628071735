import React, {useContext} from "react";
import {Card, Container} from "react-bootstrap";
import {Context} from "../store/appContext";


export const Gracias = () => {
  const {store, actions} = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag': '🇪🇸 ESP',
      'title': 'Muchas Gracias!',
      'subtitle': 'Thank You!',
      'paragraph1': 'Merci! Obrigado! Grazie!',
      'paragraph2': '',
    },
    {
      'flag': '🇺🇲 ENG',
      'title': 'Thank You!',
      'subtitle': 'Muchas Gracias!',
      'paragraph1': 'Merci! Obrigado! Grazie!',
      'paragraph2': '',
    }
  ];

  return (
    <Container className={'my-5 py-5 text-center'}>
      {/* <img src={gracias} alt={'muchas gracias'}/> */}
      <Card.Title className="display-1 fw-bold pt-5">{text[i].title}</Card.Title>
      <Card.Subtitle className="display-3 fw-bold pb-3">{text[i].subtitle}</Card.Subtitle>
      <Card.Text className="fs-4">{text[i].paragraph1}</Card.Text>
      <Card.Text className="fs-4">{text[i].paragraph2}</Card.Text>
      <h1 className="display-5">{text[i].title}</h1>
      <h2 className="display-5">{text[i].subtitle}</h2>
      <h3>{text[i].paragraph1}</h3>
      <p>{text[i].paragraph2}</p>
    </Container>
  )
}