import React, {useContext} from "react";
import {Button} from "react-bootstrap";
import {Context} from "../store/appContext.js";
import {Link} from "react-router-dom";

export const CallToActionSecondary = () => {
  const {store, actions} = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag': '🇪🇸 ESP',
      'buttonText': 'Publicaciones',
      'linkPath': '/publications',
    },
    {
      'flag': '🇺🇲 ENG',
      'buttonText': 'Publications',
      'linkPath': '/publications',
    }
  ];

  return (
    <Link to={text[i].linkPath}>
      <Button variant={'outline-primary'} className="btn-lg m-3">{text[i].buttonText}</Button>
    </Link>
  // <Button variant='outline-primary' className="btn-lg m-2" type="button">{text[i].button1}</Button>
  // <Button variant='warning' className="btn-lg m-2" type="button">{text[i].button2}</Button>
);
}

