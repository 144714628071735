import React from "react";
import { Container } from "react-bootstrap";
import {CardMain} from "../components/CardMain";
import {CardsActivities} from "../components/CardsActivities";
import {CardsLocations} from "../components/CardsLocations";

export const Home = () => {
  return (
    <Container className={"my-5"}>
      <CardMain/>
      {/*<CardsActivities/>*/}
      {/*<CardsLocations/>*/}
    </Container>
  )
};