import React from 'react';
import {createRoot} from 'react-dom/client'
// import boostrap after npm install bootstrap react-boostrap
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap-theme.css';
import './assets/styles/index.css';  // components takes precedence over default styles.

import Layout from "./Layout";  // Import your own components

const root = createRoot(document.querySelector("#root"))
root.render(<Layout/>)
