import React, {useContext} from "react";
import {Container} from "react-bootstrap";
import {Context} from "../store/appContext";
import error404 from "../assets/images/404.jpeg";

export const NoMatch = () => {
  const {store, actions} = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag': '🇪🇸 ESP',
      'title': 'Ups!',
      'paragraph1': 'Lo siento, ha ocurrido un error inesperado.',
      'paragraph2': 'Por favor, haga click en mi logo para regresar a la página principal.',
    },
    {
      'flag': '🇺🇲 ENG',
      'title': 'Oops!',
      'paragraph1': 'Sorry, an unexpected error has occurred.',
      'paragraph2': 'Please, click on my logo to return to the main page.',
    }
  ];

  return (
    <Container className={'my-5 py-5 text-center'}>
      <img src={error404} alt={'error 404'}/>
      <h1>{text[i].title}</h1>
      <p>{text[i].paragraph1}</p>
      <p>{text[i].paragraph2}</p>
    </Container>
  )
}