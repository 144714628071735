import React from "react";
import {Navbar} from "react-bootstrap";

export const Ukraine = () => {
  return (
    <Navbar.Text>
      🇺🇦 <span className='text-primary'>I stand with Ukraine</span>
    </Navbar.Text>
  )
}
