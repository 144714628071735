import React, { useContext } from "react";
import { Context } from "../store/appContext.js";
import { Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";


export const NavbarUtil = () => {
  const { store, actions } = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag' : '🇪🇸 ESP',
      'english' : '🇺🇸 Inglés',
      'spanish' : '🇪🇸 Español'
    },
    {
      'flag' : '🇺🇲 ENG',
      'english' : '🇺🇸 English',
      'spanish' : '🇪🇸 Spanish'
    }
  ];


  const handleTheme = () => {
    if (store.currentTheme.name == 'dark') {
      actions.setThemes({ name: "light", url: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" })
    } else {
      actions.setThemes({ name: "dark", url: "https://cdn.jsdelivr.net/npm/bootswatch@5.3.3/dist/slate/bootstrap.min.css" })
    }
  }

  return (
    <>
      <Nav.Link className="mx-1">
        <span  onClick={handleTheme}>
          {store.currentTheme.name == 'dark' ? 
            <FontAwesomeIcon icon={faSun}/> 
            : 
            <FontAwesomeIcon icon={faMoon} />
          }
        </span>
      </Nav.Link>
      <Nav>
        <NavDropdown title={text[i].flag} id="basic-nav-dropdown">
          <NavDropdown.Item onClick={actions.anyLanguage}>{text[i].english}</NavDropdown.Item>
          <NavDropdown.Item onClick={actions.spanishLanguage}>{text[i].spanish}</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </>
  );
}