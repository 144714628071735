import React, {useContext} from "react";
import { Context } from "../store/appContext.js";
// React-Bootstrap
import {Container, Nav, Navbar} from "react-bootstrap";


export const NavbarLegal = () => {
  const { store, actions } = useContext(Context);
  let linkClass = 'link-secondary text-decoration-none'
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag' : '🇪🇸 ESP',
      'siteName' : 'Héctor Chocobar Torrejón',
      'pp' : 'Política de Privacidad',
    },
    {
      'flag' : '🇺🇲 ENG',
      'siteName' : 'Hector Chocobar-Torrejon',
      'pp' : 'Privacy Policy',
    }
  ];

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Nav>
          <Nav.Link className={linkClass} href={"/privacy-policy"}>
              {text[i].pp}
          </Nav.Link>
        </Nav>
        <Nav>
          <Navbar.Text>
            <span>{text[i].siteName}</span>
            <span> | </span>
            <span>Copyright 2010 - {new Date().getFullYear()}</span>
          </Navbar.Text>
        </Nav>
      </Container>
    </Navbar>
  )
};
