import React from "react";
import {NavbarSocial} from "./NavbarSocial.jsx";
import {NavbarLegal} from "./NavbarLegal.jsx";


export const FooterMain = () => {

  return (
    <footer className="mt-auto">
      <NavbarSocial/>
      <NavbarLegal/>
    </footer>
  )
}