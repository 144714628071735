import React, {useContext} from "react";
import {Context} from "../store/appContext";
// Custom image
import hector from '../assets/images/hector_chocobar_torrejon.jpg'
// React-Boostrap
import {CardImg, Col, Container, Row} from "react-bootstrap";
import Card from "react-bootstrap/Card";
// Components
import {CallToActionPrimary} from "./CallToActionPrimary";
import {CallToActionSecondary} from "./CallToActionSecondary";

export const CardMain = () => {
  const {store, actions} = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag': '🇪🇸 ESP',
      'title': 'Hola, mi nombre es Héctor',
      'imgAlt': 'Héctor Chocobar Torrejón',
      'paragraph1': 'En Marzo de 1994 me gradué como Ingeniero en Sistemas de Información en la Universidad Tecnológica Nacional (Argentina).',
      'paragraph2': 'Actualmente, radicado en Uruguay, me desempeño como docente de Programación Orientada a Datos en la Universidad Católica del Uruguay y, también en modalidad remota, como Senior Mentor en los Full Stack Coding Bootcamp de 4Geeks Academy Spain.',
    },
    {
      'flag': '🇺🇲 ENG',
      'title': 'Hi, my name is Hector',
      'imgAlt': 'Hector Chocobar-Torrejon',
      'paragraph1': 'I am a Bachelor of Engineering in Computer Science (BE, CS) graduated in 1994 from National Technology University (UTN) in Argentina.',
      'paragraph2': 'Currently, with legal residence in Uruguay, I am a Professor of Data Science-oriented Programming at the Catholic University of Uruguay, and I also work remotely as a Senior Mentor in the Full Stack Coding Bootcamps of 4Geeks Academy Spain.',
    }
  ];

  return (
    <Container className="py-5">
      <Row>
        <Col md className="py-3">
          <Card.Title className="display-5 fw-bold pb-3">{text[i].title}</Card.Title>
          <Card.Text className="fs-4">{text[i].paragraph1}</Card.Text>
          <Card.Text className="fs-4">{text[i].paragraph2}</Card.Text>
          <CallToActionSecondary/>
          <CallToActionPrimary/>
        </Col>
        <Col md className="py-3" style={{textAlign: 'center'}}>
          <CardImg style={{maxWidth: '22em'}} className={'img-fluid rounded-circle'} alt={text[i].imgAlt}
                   src={hector}></CardImg>
        </Col>
      </Row>
    </Container>
  );
}