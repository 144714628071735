import React from "react";
import { Container } from "react-bootstrap";
import { CardGratitude } from "../components/CardGratitude";

export const Gratitude = () => {
  return (
    <Container className={"my-5"}>
      <CardGratitude/>
    </Container>
  )
};