import React, {useContext} from "react";
import {Context} from "../store/appContext";
// Custom image
import hector from '../assets/images/hector_chocobar_torrejon.jpg'
// React-Boostrap
import {CardImg, Col, Container, Row} from "react-bootstrap";
import Card from "react-bootstrap/Card";
// Components
import { CallToGratitudeEs } from "./CallToGratitudeEs";
import { CallToGratitudeEn } from "./CallToGratitudeEn";


export const CardGratitude = () => {
  const {store, actions} = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag': '🇪🇸 ESP',
      'title': 'A mis ex-alumnos:',
      'subtitle': '¡Gracias por este viaje!',
      'imgAlt': 'Héctor Chocobar Torrejón',
      'paragraph1': 'Durante los meses que hemos compartido juntos he tenido el honor de acompañaros en vuestro crecimiento y ver cómo os abrís camino hacia nuevas oportunidades en la industria del software y los datos. Tengo la certeza, que el esfuerzo y la dedicación que habéis puesto en este proceso os llevarán a grandes logros.',
      'paragraph2': 'Si en algún momento sientes que te he ayudado a cambiar tu rumbo profesional o simplemente deseas expresar tu agradecimiento de una manera especial, te invito a invitarme a un café virtual (¡o a una pinta de cerveza!) haciendo una pequeña donación a través de mi cuenta de PayPal. Este gesto será una caricia del alma y me permitirá seguir apoyando a más personas en su camino hacia el éxito, tal y como lo has hecho tú.',
    },
    {
      'flag': '🇺🇲 ENG',
      'title': 'To my former students:',
      'subtitle': 'Thank you for this journey!',
      'imgAlt': 'Hector Chocobar-Torrejon',
      'paragraph1': 'Over the months we have shared together I have had the honor of accompanying you in your growth and witnessing how you are forging your path towards new opportunities in the software and data industries. I am certain that the effort and dedication you have invested in this process will lead you to great achievements.',
      'paragraph2': 'If at any point you feel that I’ve helped change your professional path, or if you simply want to express your gratitude in a special way, I invite you to buy me a virtual coffee (or even a pint of beer!) by making a small donation via my PayPal account. This gesture would truly touch my heart and will allow me to continue supporting others on their journey to success, just as you have done.',
    }
  ];

  return (
    <Container className="py-5">
      <Row>
      <Col md className="py-3">
          <Card.Title className="display-5 fw-bold pb-3">{text[i].title}</Card.Title>
          <Card.Subtitle className="display-3 fw-bold pb-3">{text[i].subtitle}</Card.Subtitle>
      </Col>
      </Row>
      <Row>
        <Col md className="py-3">
          <Card.Text className="fs-4">{text[i].paragraph1}</Card.Text>
          <Card.Text className="fs-4">{text[i].paragraph2}</Card.Text>
        </Col>
        <Col lg='auto' className="bg-primary-subtle rounded-3 m-auto px-md-5 py-3" style={{textAlign: 'center'}}>
          {store.isSpanish ? <CallToGratitudeEs/> : <CallToGratitudeEn/>}
        </Col>
      </Row>
    </Container>
  );
}
