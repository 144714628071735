import React, {useContext} from "react";
import {Button} from "react-bootstrap";
import {Context} from "../store/appContext.js";
import {Link} from "react-router-dom";

export const CallToActionPrimary = () => {
  const {store, actions} = useContext(Context);
  let i = store.isSpanish ? 0 : 1;
  let text = [
    {
      'flag': '🇪🇸 ESP',
      'buttonText': 'Iniciemos un proyecto',
      'linkPath': '/request',
    },
    {
      'flag': '🇺🇲 ENG',
      'buttonText': 'Let\'s start a project',
      'linkPath': '/request',
    }
  ];

  return (
    <Link to={text[i].linkPath}>
      <Button variant={'warning'} className={'btn-lg mx-3'}>{text[i].buttonText}</Button>
    </Link>
    // <Button variant='warning' className="btn-lg m-2" type="button">{text[i].button2}</Button>
  );
}

