import React, {useContext} from "react";
import {Container} from "react-bootstrap";
import {Context} from "../store/appContext";

export const PrivacyPolicy = () => {
  const {store, actions} = useContext(Context);
  if (store.isSpanish) {
    return (
      <Container className={'my-5 py-5'}>
        <h1 className={'text-center'}>Política de Privacidad</h1>
        <div>
          <h2>Quiénes somos</h2>
          <p>La dirección de nuestro sitio web es: https://chocobar.net.</p>
          <h2>Comentarios</h2>
          <p>Cuando los visitantes dejan comentarios en el sitio, recopilamos los datos que se muestran en el formulario
            de comentarios, y también datos del visitante, tales como: la dirección IP y la cadena del agente de usuario
            del navegador para ayudar a la detección de spam.</p>
          <p>Se puede proporcionar al servicio Gravatar una cadena anónima creada a partir de su dirección de correo
            electrónico (también llamada hash), para determinar si lo estás utilizando. La política de privacidad del
            servicio Gravatar está disponible en: https://automattic.com/privacy/.
            Después de la aprobación de su comentario, su foto de perfil es visible para el público en el contexto de
            su comentario.</p>
          <h2>Medios</h2>
          <p>Si carga imágenes en el sitio web, debe evitar cargar imágenes con datos de ubicación incrustados.
            Los visitantes del sitio web pueden descargar y extraer cualquier dato de ubicación de las imágenes del
            sitio web.</p>
          <h2>Cookies</h2>
          <p>Si deja un comentario en nuestro sitio, puede optar por guardar su nombre, dirección de correo electrónico
            y sitio web en cookies.
            Estos son para su conveniencia, para que no tenga que volver a completar sus datos cuando deje otro
            comentario. Estas cookies durarán un año.</p>
          <p>Si visita nuestra página de inicio de sesión, estableceremos una cookie temporal para determinar si su
            navegador acepta cookies. Esta cookie no contiene datos personales y será descartada cuando cierra su
            navegador.</p>
          <p>Cuando inicie sesión, también configuraremos varias cookies para guardar su información de inicio de sesión
            y sus opciones de pantalla. Las cookies de inicio de sesión duran dos días y las cookies de opciones de
            pantalla duran un año.
            Si selecciona "Acuérdate de mí", su inicio de sesión persistirá durante dos semanas. Si cierra sesión en su
            cuenta, las cookies de inicio de sesión serán eliminadas.</p>
          <p>Si edita o publica un artículo, se guardará una cookie adicional en su navegador. Esta cookie no incluye
            datos personales y simplemente indica el ID de publicación del artículo que acaba de editar. Caduca después
            de un día.</p>
          <h2>Contenido incrustado de otros sitios web</h2>
          <p>Los artículos de este sitio pueden incluir contenido incrustado de otros sitios web (por ejemplo: videos,
            imágenes, artículos, etc.) y se comportan exactamente de la misma manera que si el visitante hubiera
            visitado el otro sitio web.</p>
          <p>Estos sitios web pueden recopilar datos sobre usted, usar cookies, incorporar seguimiento adicional de
            terceros y monitorear su interacción con ese contenido incrustado, incluido el seguimiento de su interacción
            con el contenido incrustado si usted tiene una cuenta y ha iniciado sesión en ese sitio web.</p>
          <h2>Con quién compartimos sus datos</h2>
          <p>Si solicita un restablecimiento de contraseña, su dirección IP se incluirá en el correo electrónico de
            restablecimiento.</p>
          <h2>Cuánto tiempo conservamos sus datos</h2>
          <p>Si deja un comentario, el comentario y sus metadatos se conservan indefinidamente. Esto es para que podamos
            reconocer y aprobar los comentarios de seguimiento automáticamente en lugar de mantenerlos en una cola de
            moderación.</p>
          <p>Para los usuarios que se registran en nuestro sitio web (si los hay), también almacenamos la información
            personal que proporcionan en su perfil del usuario. Todos los usuarios pueden ver, editar o eliminar su
            información personal en cualquier momento (excepto que no pueden cambiar su nombre de usuario). Los
            administradores del sitio web también pueden ver y editar esa información.</p>
          <h2>Qué derechos tienes sobre tus datos</h2>
          <p>Si tiene una cuenta en este sitio o ha dejado comentarios, puede solicitar recibir un archivo exportado de
            el datos personales que tenemos sobre usted, incluidos los datos que nos haya proporcionado. También puede
            solicitar que nosotros borremos cualquier dato personal que tengamos sobre usted. Esto no incluye ningún
            dato que estemos obligados a conservar para fines administrativos, legales o de seguridad.</p>
          <h2>Dónde enviamos tus datos</h2>
          <p>Los comentarios de los visitantes pueden revisarse a través de un servicio automático de detección de
            spam.</p>
        </div>
      </Container>
    );
  } else {
    return (
      <Container className={'my-5 py-5'}>
        <h1 className={'text-center'}>Privacy Policy</h1>
        <div>
          <h2>Who we are</h2>
          <p>Our website address is: https://chocobar.net.</p>
          <h2>Comments</h2>
          <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the
            visitor’s
            IP address and browser user agent string to help spam detection.</p>
          <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar
            service
            to see if you are using it. The Gravatar service privacy policy is available here:
            https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the
            public in
            the context of your comment.</p>
          <h2>Media</h2>
          <p>If you upload images to the website, you should avoid uploading images with embedded location data
            included.
            Visitors to the website can download and extract any location data from images on the website.</p>
          <h2>Cookies</h2>
          <p>If you leave a comment on our site you may opt-in to saving your name, email address and website in
            cookies.
            These are for your convenience so that you do not have to fill in your details again when you leave another
            comment. These cookies will last for one year.</p>
          <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies.
            This
            cookie contains no personal data and is discarded when you close your browser.</p>
          <p>When you log in, we will also set up several cookies to save your login information and your screen display
            choices. Login cookies last for two days, and screen options cookies last for a year. If you select
            “Remember Me”,
            your login will persist for two weeks. If you log out of your account, the login cookies will be
            removed.</p>
          <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes
            no
            personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>
          <h2>Embedded content from other websites</h2>
          <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content
            from
            other websites behaves in the exact same way as if the visitor has visited the other website.</p>
          <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor
            your
            interaction with that embedded content, including tracking your interaction with the embedded content if you
            have
            an account and are logged in to that website.</p>
          <h2>Who we share your data with</h2>
          <p>If you request a password reset, your IP address will be included in the reset email.</p>
          <h2>How long we retain your data</h2>
          <p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize
            and
            approve any follow-up comments automatically instead of holding them in a moderation queue.</p>
          <p>For users that register on our website (if any), we also store the personal information they provide in
            their
            user profile. All users can see, edit, or delete their personal information at any time (except they cannot
            change
            their username). Website administrators can also see and edit that information.</p>
          <h2>What rights you have over your data</h2>
          <p>If you have an account on this site, or have left comments, you can request to receive an exported file of
            the
            personal data we hold about you, including any data you have provided to us. You can also request that we
            erase
            any personal data we hold about you. This does not include any data we are obliged to keep for
            administrative,
            legal, or security purposes.</p>
          <h2>Where we send your data</h2>
          <p>Visitor comments may be checked through an automated spam detection service.</p>
        </div>
      </Container>
    );
  }
};

