import React from "react";
import {Container} from "react-bootstrap";
import {InlineWidget} from "react-calendly";

export const Request = () => {
  const calendlyUrl = 'https://calendly.com/hcht/event'

  return (
    <Container className={"my-5"}>
      <Container className="py-5">
        <InlineWidget url={calendlyUrl}
                      styles={{height: '800px'}}
                      pageSettings={{
                        backgroundColor: 'ffffff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '00a2ff',
                        textColor: '4d5055'
                      }}/>
      </Container>
    </Container>
  )
}