import React, { useContext } from "react";
import { Context } from "../store/appContext.js";
import { Container } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faGithub, faStackOverflow} from "@fortawesome/free-brands-svg-icons";
import { faFacebookF, faInstagram, faWordpressSimple} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {Ukraine} from "./Ukraine";
import {Link} from "react-router-dom";

export const NavbarSocial = () => {
  const { store, actions } = useContext(Context);
  const bgColor = "#"+store.style.bgColorElement;
  return (
      <Navbar bg="light" expand="lg">
        <Container>
          <nav className="nav">
            <Link className="nav-link" target="_blank" to="https://linkedin.com/in/hector-chocobar">
              <FontAwesomeIcon color={bgColor} icon={faLinkedinIn} />
            </Link>
            <Link className="nav-link" target="_blank" to="https://github.com/hchocobar">
              <FontAwesomeIcon color={bgColor} icon={faGithub} />
            </Link>
            <Link className="nav-link" target="_blank" to="https://stackoverflow.com/users/18981955/hector-chocobar-torrejon">
              <FontAwesomeIcon color={bgColor} icon={faStackOverflow} />
            </Link>
            <Link className="nav-link" target="_blank" to="https://profiles.wordpress.org/hchocobar/">
              <FontAwesomeIcon color={bgColor} icon={faWordpressSimple} />
            </Link>
            <Link className="nav-link" target="_blank" to="https://facebook.com/in/hector.h.chocobar">
              <FontAwesomeIcon color={bgColor} icon={faFacebookF} />
            </Link>
            <Link className="nav-link" target="_blank" to="https://instagram.com/in/hector.chocobar">
              <FontAwesomeIcon color={bgColor} icon={faInstagram} />
            </Link>
            <Link className="nav-link" target="_blank" to="mailto:hector@chocobar.net">
              <FontAwesomeIcon color={bgColor} icon={faEnvelope} />
            </Link>
          </nav>
          <Ukraine/>
        </Container>
      </Navbar>
  );
}
