import { loadScript } from "@paypal/paypal-js";


const getState = ({ getStore, getActions, setStore }) => {
	return {
		store: {
      style : {bgColorElement : '0c579b',},
			isSpanish: false,  // Support two language: Spanish (true) and English (false)
      currentTheme: { 
        name: "light", 
        url: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" 
      },
      paypal: null
		},
		actions: {
			spanishLanguage: () => { 
        setStore({ isSpanish: true, });  // Set language in Spanish
        getActions().loadPaypalEs();  // Load Spanish paypal form
      },
			anyLanguage: () => { 
        setStore({ isSpanish: false, });  // Set language in English
        getActions().loadPaypalEn();  // Load English paypal form
      },
      getTheme: () => {
        const savedTheme = localStorage.getItem("theme")
        if (savedTheme) {
          setStore({ currentTheme: JSON.parse(savedTheme) })
        }
      },
      setThemes: (theme) => {
        // Set theme dark or light
        setStore({ currentTheme: theme});
        localStorage.setItem("theme", JSON.stringify(getStore().currentTheme));
      },
      loadPaypalEs: async () => {
        // Load Paypal forms (Spanish)
        const response = await loadScript({
          clientId: "BAAQ_R3KL-xyeVla1HbAZ75Ex3YPB15L9h5Gn_mQFW6trFYJxkFqDH0b1OeX_rjlfjF0LSZvPk13qpoUVw",
          components : ["hosted-buttons"],
          disableFunding: ['venmo'],
          currency: 'USD'
        })
        setStore({paypal: response})
      },
      loadPaypalEn: async () => {
        // Load Paypal forms (English)
        const response = await loadScript({
          clientId: "BAAQ_R3KL-xyeVla1HbAZ75Ex3YPB15L9h5Gn_mQFW6trFYJxkFqDH0b1OeX_rjlfjF0LSZvPk13qpoUVw",
          components : ["hosted-buttons"],
          disableFunding: ['venmo'],
          currency: 'USD'
        })
        setStore({paypal: response})
      } 
		},
	};
};

export default getState;